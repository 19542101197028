/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-connect-your-horizon-hearing-aids-to-streamline-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-connect-your-horizon-hearing-aids-to-streamline-tv",
    "aria-label": "how to connect your horizon hearing aids to streamline tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to connect your Horizon hearing aids to Streamline TV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Watching TV comfortably with clear sound is essential, especially when using hearing aids. If you have Bluetooth-enabled ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon hearing aids"), " from ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", you can easily connect them to your television using StreamLine TV. This allows the TV sound to stream directly into your hearing aids, giving you a clearer, more enjoyable experience. Follow these simple steps to pair your ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go hearing aids"), " with StreamLine TV, and enjoy seamless audio without the need for additional devices like headphones or external speakers."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Connect StreamLine TV"), ": Plug it into a power outlet and connect it to your TV using either an RCA (white and red cables) or TOSLINK cable (optical cable that comes out from the TV to the in channel on the StreamLine TV)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Activate Pairing Mode"), ": Turn off your hearing aids, then turn them back on. Do this by putting them in the charging box, wait for 5 seconds and take them back out to activate pairing mode. This mode stays active for 3 minutes."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Pair with StreamLine TV"), ": The LEDs on the StreamLine TV will start blinking in blue for 30 seconds to indicate they are looking for a hearing aid signal. Place your Horizon hearing aids on top of the StreamLine TV to complete the pairing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Confirm Pairing"), ": Once paired successfully, the LED representing the power should be green, and the LED representing the TV should be blue."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Insert Hearing Aids"), ": Put your hearing aids in your ears."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Use Horizon App"), ": Open the Horizon app on your smartphone and tap on the StreamLine TV icon to start streaming the TV audio directly to your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Enjoy TV Audio"), ": Now, the sound from your TV will be streamed directly to your hearing aids."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "streamline-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#streamline-tv",
    "aria-label": "streamline tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "StreamLine TV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Experience Direct Streaming in Dolby Digital Sound Quality. StreamLine TV, paired with Horizon hearing aids, seamlessly delivers audio from your television directly into your Horizon hearing aids, ensuring exceptional Dolby Digital quality. It offers effortless, fully automatic operation for a cutting-edge wireless listening experience."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "support-for-multiple-listeners",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#support-for-multiple-listeners",
    "aria-label": "support for multiple listeners permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Support for Multiple Listeners"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ideal for families with multiple members using hearing aids, StreamLine TV supports simultaneous streaming to several pairs of compatible Horizon hearing aids at once, enhancing the TV-watching experience together."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "easy-remote-control-via-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#easy-remote-control-via-app",
    "aria-label": "easy remote control via app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Easy Remote Control via App"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Manage TV streaming effortlessly with the Horizon app. Start or pause streaming and adjust volume directly from your smartphone, eliminating the need for additional remote controls."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "compatibility-with-horizon-connectivity-technologies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#compatibility-with-horizon-connectivity-technologies",
    "aria-label": "compatibility with horizon connectivity technologies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Compatibility with Horizon Connectivity Technologies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "StreamLine TV integrates seamlessly with other Horizon streaming technologies, allowing you to seamlessly switch between TV audio and phone calls. When a call comes in, TV streaming pauses automatically."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "wide-compatibility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wide-compatibility",
    "aria-label": "wide compatibility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wide Compatibility"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "StreamLine TV is compatible with all televisions equipped with:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "TOSLINK cable (optical / digital)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cinch cable (RCA / analog)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Jack adapter"), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "key-features-of-the-streamline-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#key-features-of-the-streamline-tv",
    "aria-label": "key features of the streamline tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Key Features of the StreamLine TV"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatic pairing with Signia hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Direct streaming up to 10m"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Optical input and output"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Supports Dolby Digital sound"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Simultaneous streaming to multiple sets of hearing aids with one StreamLine TV"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Enhance your TV-watching experience with StreamLine TV, designed for ease of use and superior sound quality."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Connecting your Bluetooth hearing aids to StreamLine TV is a simple process that can greatly enhance your listening experience. By following the steps outlined, you’ll be able to enjoy high-quality, direct audio streaming in no time. If you encounter any issues or need further assistance, the team at hear.com is here to help. Our experts are ready to answer any questions and provide personalized support to ensure you get the most out of your hearing aids. Contact us today at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786) 520-2456"), " to learn more!"), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for your no-risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
